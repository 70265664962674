import axios from "axios";
import store from '~/store';
import EventBus from "~/plugins/event-bus";
import Vue from "vue";

// Request interceptor
axios.interceptors.request.use(request => {
    const token = store.getters['auth/token'];

    if (token) {
        request.headers.common['Authorization'] = `Bearer ${token}`;
    }

    const locale = store.getters['lang/locale'];
    if (locale) {
        request.headers.common['Accept-Language'] = locale;
    }
    return request;
});

// Response interceptor
axios.interceptors.response.use(response => response, error => {
    const {status, data} = error.response;

    if (status === 503 && data === 'Podio authentication has expired') {
        store.dispatch('podio/updatePodioSession');
    }

    if (status === 429 && data.error === 'PodioRateLimitExceeded') {
        EventBus.$emit('RATE_LIMIT_TOAST', {
            type: 'error', message: Vue.i18n.translate('messages.toast.podio_rate_limit_error', {
                limit_percentage: data.data.user_configured_threshold,
                minute_string: data.data.ttl > 0 ? Vue.moment().startOf('day').seconds(data.data.ttl).format('m[m]:s[s]') : Vue.i18n.translate('messages.toast.about_an_hour'),
            })
        });
    }

    if (status >= 500 && data.exception && data.message) {
        EventBus.$emit('TOAST', 'error', data.exception + ': ' + data.message, {displayTime: 9999});
    }

    if (status === 401 && store.getters['auth/check']) {
        const originalRequestConfig = error.response.config ? error.response.config : {};
        if (originalRequestConfig.url === "/api/token/refresh") {
            store.commit('auth/logout');
        } else {
            if (store.getters['auth/refreshToken']) {
                // if there is already a promise for refreshing the token, return that
                if (store.getters['auth/refreshPromise']) {
                    return store.getters['auth/refreshPromise'].then((() => {
                        originalRequestConfig.headers['Authorization'] = 'Bearer ' + store.getters['auth/token'];
                        return axios(originalRequestConfig);
                    }));
                }
                // attempt to refresh the token
                return store.dispatch('auth/refreshAccessToken').then(() => {
                    originalRequestConfig.headers['Authorization'] = 'Bearer ' + store.getters['auth/token'];
                    return axios(originalRequestConfig);
                }).catch(() => {
                    store.commit('auth/logout');
                });
            }
            store.commit('auth/logout');
        }
    }
    return Promise.reject(error);
});
