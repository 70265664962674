var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drawer-container" },
    [
      _c("DxToolbar", {
        staticClass: "drawer-toolbar",
        attrs: { items: _vm.toolbarContent, height: 50 },
      }),
      _vm._v(" "),
      _c(
        "DxDrawer",
        {
          attrs: {
            "opened-state-mode": "overlap",
            "animation-duration": _vm.animationDuration,
            position: "left",
            "reveal-mode": "slide",
            opened: _vm.drawerOpened,
            "close-on-outside-click": true,
            height: "100%",
            template: "listMenu",
          },
          on: {
            "update:opened": function ($event) {
              _vm.drawerOpened = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "listMenu",
              fn: function () {
                return [
                  _c("DxList", {
                    staticClass:
                      "panel-list dx-theme-accent-as-background-color",
                    attrs: {
                      width: 200,
                      "data-source": _vm.navigationItems,
                      "active-state-enabled": false,
                      "hover-state-enabled": false,
                      "focus-state-enabled": false,
                    },
                    on: { "item-click": _vm.navigationItemClick },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("DxScrollView", [
            _c(
              "div",
              {
                staticClass: "dx-theme-background-color",
                attrs: { id: "content" },
              },
              [_vm._t("default")],
              2
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }