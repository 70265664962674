import Vue from "vue";
import router from "~/router";
import App from "./App";
import '~/plugins';
import VueRouter from "vue-router";
import store from "~/store";
import VueMoment from "vue-moment";
import ucFirst from "~/filters/ucFirst";

Vue.use(VueRouter);
Vue.use(VueMoment);

// Global filters
Vue.filter('ucFirst', ucFirst);

Vue.prototype.$appConfig = window.appConfig ?? null;

new Vue({
    router,
    store,
    ...App
});
