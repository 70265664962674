import { render, staticRenderFns } from "./LayoutNavigationSidebar.vue?vue&type=template&id=c1a742d0&"
import script from "./LayoutNavigationSidebar.vue?vue&type=script&lang=js&"
export * from "./LayoutNavigationSidebar.vue?vue&type=script&lang=js&"
import style0 from "./LayoutNavigationSidebar.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/podiogantt/domains/gantt.podio.xicero.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c1a742d0')) {
      api.createRecord('c1a742d0', component.options)
    } else {
      api.reload('c1a742d0', component.options)
    }
    module.hot.accept("./LayoutNavigationSidebar.vue?vue&type=template&id=c1a742d0&", function () {
      api.rerender('c1a742d0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/app/js/layouts/LayoutNavigationSidebar.vue"
export default component.exports