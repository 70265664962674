<template>
    <section class="section">
        <slot/>
    </section>
</template>

<script>
export default {
    name: "LayoutWithoutNavigation",
    data() {
        return {}
    }
}
</script>

<style scoped>

</style>
