var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notification is-warning" },
    [
      _vm._v("\n    " + _vm._s(_vm.$t("app.podio.not_authorized")) + "\n    "),
      _c("router-link", { attrs: { to: { name: "Settings" } } }, [
        _vm._v(
          " " + _vm._s(_vm._f("ucFirst")(_vm.$t("app.navigation.settings")))
        ),
      ]),
      _vm._v("\n    .\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }