var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dx-viewport" },
    [
      _vm.initializing
        ? _c("DxLoadPanel", {
            attrs: {
              visible: true,
              "show-indicator": true,
              "show-pane": false,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.layout && !_vm.initializing
        ? _c(
            _vm.layout,
            { tag: "component" },
            [
              !_vm.session && _vm.publicRoute
                ? _c(
                    "section",
                    { staticClass: "section py-2" },
                    [_c("PodioNotAuthorized")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("router-view"),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }