// auth.js
export const LOGOUT = 'LOGOUT';
export const SAVE_TOKEN = 'SAVE_TOKEN';
export const SAVE_REFRESH_TOKEN = 'SAVE_REFRESH_TOKEN';
export const FETCH_USER = 'FETCH_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_IMPERSONATING = 'UPDATE_IMPERSONATING';

// podio.js
export const SAVE_APPLICATIONS = 'SAVE_APPLICATIONS';
export const SAVE_APPLICATION_FIELDS = 'SAVE_APPLICATION_FIELDS';
export const SET_PODIO_SESSION_DATA = 'SET_PODIO_SESSION_DATA';
