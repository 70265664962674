export const state = {
    initializing: true,
    initializationPromise: null,
}

export const getters = {
    init: state => state.initializationPromise,
    initializing: state => state.initializing
}

export const actions = {
    Initialize({state, dispatch}) {
        state.initializationPromise = Promise.all([dispatch('auth/fetchUser', {}, {root: true})])
            .then(() => state.initializing = false);
        return state.initializationPromise;
    }
}
