<template>
    <div class="dx-viewport">
        <DxLoadPanel
            v-if="initializing"
            :visible="true"
            :show-indicator="true"
            :show-pane="false"
        />
        <component v-if="layout && !initializing" :is="layout">
            <section class="section py-2" v-if="!session && publicRoute">
                <PodioNotAuthorized/>
            </section>
            <router-view/>
        </component>
    </div>
</template>
<script>
import EventBus from "~/plugins/event-bus";
import DxLoadPanel from "devextreme-vue/load-panel";
import PodioNotAuthorized from "~/components/PodioNotAuthorized";
import {mapGetters} from "vuex";
import {setLocale} from "./plugins/i18n";
import notify from "devextreme/ui/notify";

// Load layout components dynamically.
const requireContext = require.context('./layouts', false, /.*\.vue$/);

const layouts = requireContext.keys()
    .map(file =>
        [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
    )
    .reduce((components, [name, component]) => {
        components[name] = component.default ?? component;
        return components;
    }, {});

export default {
    components: {DxLoadPanel, PodioNotAuthorized},
    el: '#app',
    name: 'App',
    data() {
        return {
            layout: null,
        }
    },
    computed: {
        publicRoute() {
            return this.$route ? this.$route.meta.requiresAuth ?? true : true;
        },
        ...mapGetters('app', ['initializing']),
        ...mapGetters('podio', ['session']),
    },
    beforeCreate() {
        EventBus.$on('logout', () => this.$router.push({name: 'Login'}));
        this.$store.dispatch('app/Initialize');
        this.$store.dispatch('podio/InitSession');
    },
    created() {
        this.setLayout();
        EventBus.$on('userUpdated', (payload) => {
            this.$store.dispatch('podio/updateApplications');
            let {user} = payload;
            if (user) {
                setLocale(user.language_code ?? 'en');
            }
        });
        EventBus.$on('RATE_LIMIT_TOAST', (payload) => {
            let options = {
                message: payload.message,
                type: payload.type,
                displayTime: 10000,
                position: {at: 'top center', offset: '0 50'},
                width: 600,
            };
            notify(options);
        });
    },
    watch: {
        '$route.name': function () {
            this.setLayout();
        }
    },
    methods: {
        setLayout() {
            let {layout} = this.$route?.meta;
            if (layout) {
                this.layout = layouts[layout];
                return;
            }
            this.layout = layouts['LayoutNavigationSidebar'];
        }
    }
}
</script>
<style lang="scss">

</style>
