import axios from 'axios';
import Cookies from 'js-cookie';
import * as types from '~/store/mutation-types';
import EventBus from '~/plugins/event-bus';

const TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";

export const state = {
    user: null,
    token: Cookies.get(TOKEN_KEY),
};

export const getters = {
    user: state => state.user,
    token: state => state.token,
    refreshToken: state => state.refreshToken,
    refreshPromise: state => state.refreshPromise,
    check: state => state.user !== null,
};

export const mutations = {
    [types.SAVE_TOKEN](state, token) {
        state.token = token;
    },
    [types.SAVE_REFRESH_TOKEN](state, refreshToken) {
        state.refreshToken = refreshToken;
    },

    [types.LOGOUT](state) {
        state.user = null;
        state.refreshToken = null;
        state.token = null;
    },

    [types.UPDATE_USER](state, {user}) {
        state.user = user;
    },
};

export const actions = {
    saveTokens({commit}, {token, remember, refresh_token: refreshToken}) {
        commit(types.SAVE_TOKEN, token);
        commit(types.SAVE_REFRESH_TOKEN, refreshToken);
        Cookies.set(TOKEN_KEY, token, {expires: remember ? 365 : 7});
        Cookies.set(REFRESH_TOKEN_KEY, refreshToken, {expires: remember ? 365 : 7});
    },

    async fetchUser({state, commit}) {
        try {
            EventBus.$emit('loading');
            const {data} = await axios.get('/api/user');
            commit(types.UPDATE_USER, {user: data});
            EventBus.$emit('userUpdated', {user: state.user});
        } catch (e) {
            commit(types.LOGOUT);
        } finally {
            EventBus.$emit('doneLoading');
        }
    },

    refreshAccessToken({getters, commit, dispatch, state}) {
        let promise = new Promise((resolve, reject) =>
            axios.post('/api/token/refresh', {refreshToken: getters.refreshToken,})
                .then((response) => {
                    let {data} = response;
                    return dispatch('saveTokens', {
                        token: data.access_token,
                        refresh_token: data.refresh_token,
                    })
                        .then(() => resolve())
                        .catch(error => reject(error));
                })
                .catch((error) => {
                    console.error('failed to refresh access token');
                    commit(types.LOGOUT);
                    Cookies.remove(TOKEN_KEY);
                    Cookies.remove(REFRESH_TOKEN_KEY);
                    reject(error);
                }))
            .finally(() => state.refreshPromise = null);
        state.refreshPromise = promise;
        return promise;
    },

    updateUser({commit}, payload) {
        commit(types.UPDATE_USER, payload);
    },

    async logout({commit, getters}) {
        try {
            EventBus.$emit('loading');
            await axios.post('/api/auth/logout');
            commit(types.LOGOUT);
            Cookies.remove(TOKEN_KEY);

            EventBus.$emit('logout');
        } catch (e) {
            console.error(e);
        } finally {
            EventBus.$emit('doneLoading');
        }
    },
};
