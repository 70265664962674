import Vue from 'vue';
import store from "~/store";
import vuexI18n from 'vuex-i18n';
import {loadMessages as devExtremeLoadMessages, locale as devExtremeLocale} from "devextreme/localization";

Vue.use(vuexI18n.plugin, store);

// default to first available locale
setLocale(window.appConfig?.locales?.[0] ?? 'en');

export function setLocale(locale) {
    if (Vue.i18n.localeExists(locale)) {
        Vue.i18n.set(locale);
        return Promise.resolve(locale);
    } else {
        return loadMessages(locale).then(result => {
            if (result.default.devextreme) {
                devExtremeLoadMessages({[locale]: result.default.devextreme});
                devExtremeLocale(locale);
            }
            Vue.i18n.set(locale);
        }).catch(err => console.error(err));
    }

}

function loadMessages(locale) {
    return new Promise((resolve, reject) => {
        return import(/* webpackChunkName: "lang-[request]" */ `../../../generated/lang/${locale}.js`).then(result => {
            Vue.i18n.add(locale, result.default ?? result);
            resolve(result);
        }).catch(error => reject(error));
    })
}
