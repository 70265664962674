import {SAVE_APPLICATIONS, SAVE_APPLICATION_FIELDS, SET_PODIO_SESSION_DATA} from "../mutation-types";
import axios from "axios";
import EventBus from "~/plugins/event-bus";

export const state = {
    session: null,
    applications: null,
    applicationFields: {},
    rateLimit: null,
    userSettings: null,
}

export const getters = {
    applications: (state) => state.applications,
    getApplication: (state) => (id) => state.applications?.find(a => a.id === id),
    applicationFields: (state) => state.applicationFields,
    applicationFieldsById: (state) => (appId) => state.applicationFields[appId] ?? null,
    session: (state) => state.session,
}

export const mutations = {
    [SAVE_APPLICATIONS](state, applications) {
        state.applications = applications;
    },
    [SAVE_APPLICATION_FIELDS](state, payload) {
        let {appId, fields} = payload;
        state.applicationFields[appId] = fields;
        state.applicationFields = {...state.applicationFields};
    },
    [SET_PODIO_SESSION_DATA](state, {podio_session, rate_limit, settings}) {
        state.session = podio_session;
        state.rateLimit = rate_limit;
        state.userSettings = {rateLimitPercentage: settings.rate_limit_percentage};
    },
}

export const actions = {
    updateApplications({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('/api/applications').then(response => {
                commit(SAVE_APPLICATIONS, response.data);
                EventBus.$emit('applications-updated');
                resolve(response);
            }).catch(error => reject(error));
        });
    },
    getFieldsForApplication({getters, commit, state}, appId) {
        return new Promise((resolve, reject) => {
            if (getters.applicationFieldsById(appId)) {
                resolve(getters.applicationFieldsById(appId));
            } else {
                axios.get(`/api/applications/${appId}/fields`).then(response => {
                    let {data} = response;
                    commit(SAVE_APPLICATION_FIELDS, {
                        appId: appId,
                        fields: data,
                    })
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
            }
        });
    },
    InitSession({dispatch}) {
        return dispatch('updatePodioSession');
    },
    updatePodioSession({commit}) {
        return axios.get('/api/user/settings').then(response => {
            let {data} = response;
            commit(SET_PODIO_SESSION_DATA, data);
        }).catch(err => console.error(err));
    }
}
