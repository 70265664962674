import store from "~/store";

let parentComponent = {
    template: `<router-view/>`
};

const routes = [
    {path: '/', component: getViewComponent('Home'), name: 'Home', meta: {icon: 'home'}},
    {
        path: '/charts',
        component: parentComponent,
        name: 'Charts',
        meta: {icon: 'chart-bar'},
        redirect: {name: 'ChartOverview'},
        children: [
            {
                path: '',
                component: getViewComponent('GanttChartOverviewView'),
                name: 'ChartOverview',
                meta: {icon: 'preferences'}
            },
            {
                path: ':id',
                component: getViewComponent('GanttChartView'),
                name: 'ChartDetails',
                meta: {icon: 'preferences'}
            },
        ]
    },
    {path: '/settings', component: getViewComponent('Settings'), name: 'Settings', meta: {icon: 'cog'}},
    {
        path: '/login',
        component: getViewComponent('Login'),
        name: 'Login',
        meta: {icon: 'sign-in', layout: 'LayoutWithoutNavigation', requiresAuth: false}
    }
];

let exemptFromAuthentication = routes.reduce((c, r) => {
    if (r.meta?.hasOwnProperty("requiresAuth") && r.meta.requiresAuth === false) {
        c.push(r.name);
    }
    return c;
}, []);

function getViewComponent(name) {
    return () =>
        import(`../views/${name}`).then(c => {
            if (!exemptFromAuthentication.includes(name)) {
                (c.default ?? c).beforeRouteEnter = (to, from, next) => {
                    return store.getters['app/init'].then(() => {
                        if (!store.getters['auth/check']) {
                            next({name: 'Login', params: {returnRoute: to}});
                        } else {
                            next();
                        }
                    });
                }
            }
            return c;
        });
}

export default routes;
