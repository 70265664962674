<template>
    <div class="notification is-warning">
        {{ $t('app.podio.not_authorized') }}
        <router-link :to="{name:'Settings'}"> {{ $t('app.navigation.settings') | ucFirst }}</router-link>
        .
    </div>
</template>

<script>
export default {
    name: "PodioNotAuthorized"
}
</script>

<style scoped>

</style>
