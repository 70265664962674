<template>
    <div class="drawer-container">
        <DxToolbar :items="toolbarContent" class="drawer-toolbar" :height="50">
            <!--            <template #menuItemTemplate="{data}">-->
            <!--                <div v-if="data.type === 'trigger'"-->
            <!--                     class="p-0 pr-3 is-flex is-align-items-center is-justify-content-space-between mr-2">-->
            <!--                    <div class="avatar is-flex is-align-items-center is-justify-content-center ml-2 mr-3">{{-->
            <!--                            getAvatar(user ? user.name : null)-->
            <!--                        }}-->
            <!--                    </div>-->
            <!--                    <p class="mr-3">{{ user ? user.name : ''}}</p>-->
            <!--                    <i class="fa-solid fa-caret-down"></i>-->
            <!--                </div>-->
            <!--                <p v-else class="px-4 py-2">{{ data.name }}</p>-->
            <!--            </template>-->
        </DxToolbar>
        <DxDrawer
            :opened-state-mode="'overlap'"
            :animation-duration="animationDuration"
            :position="'left'"
            :reveal-mode="'slide'"
            :opened.sync="drawerOpened"
            :close-on-outside-click="true"
            :height="'100%'"
            template="listMenu"
        >
            <template #listMenu>
                <DxList
                    :width="200"
                    :data-source="navigationItems"
                    :active-state-enabled="false"
                    :hover-state-enabled="false"
                    :focus-state-enabled="false"
                    @item-click="navigationItemClick"
                    class="panel-list dx-theme-accent-as-background-color"
                />
            </template>
            <DxScrollView>
                <div
                    id="content"
                    class="dx-theme-background-color"
                >
                    <slot/>
                </div>
            </DxScrollView>
        </DxDrawer>
    </div>
</template>

<script>
import DxDrawer from "devextreme-vue/drawer";
import DxToolbar, {DxItem} from "devextreme-vue/toolbar";
import DxList from 'devextreme-vue/list';
import DxScrollView from "devextreme-vue/scroll-view";
import routes from "~/router/routes";
import EventBus from "~/plugins/event-bus";
import {mapGetters} from "vuex";

export default {
    name: "LayoutNavigationSidebar",
    components: {DxDrawer, DxToolbar, DxList, DxScrollView, DxItem},
    data() {
        return {
            drawerOpened: false,
            animationDuration: 400,
            toolbarContent: [{
                widget: 'dxButton',
                location: 'before',
                options: {
                    icon: 'menu',
                    onClick: () => {
                        this.drawerOpened = !this.drawerOpened;
                    },
                },
            },
                // {
                //     widget: 'dxMenu',
                //     location: 'after',
                //     options: {
                //         displayExpr: "name",
                //         itemTemplate: 'menuItemTemplate',
                //         items: [{
                //             id: 1,
                //             name: '',
                //             type: 'trigger',
                //             items: [
                //                 {
                //                     id: 2,
                //                     name: 'Logout',
                //                     onClick: () => {
                //                         this.triggerLogout();
                //                     },
                //                 }
                //             ]
                //         },
                //         ]
                //     }
                // }
            ],
            defaultNavigationItems: [
                {
                    text: this.$options.filters.ucFirst(this.$t('auth.logout')),
                    icon: 'fas fa-sign-out-alt',
                    action: this.triggerLogout
                }
            ]
        }
    },
    watch: {
        drawerOpened: function () {
            setTimeout(function () {
                this.$nextTick(() => EventBus.$emit('sidebar-opened-state-changed'));
            }.bind(this), this.animationDuration);
        }
    },
    computed: {
        navigationItems() {
            let routeItems = routes.filter(r => {
                return r.meta?.hasOwnProperty("requiresAuth") ? r.meta.requiresAuth !== false : true;
            }).map(r => {
                return {
                    text: this.$options.filters.ucFirst(this.$t('app.navigation.' + r.name.toLowerCase())),
                    icon: 'fas fa-' + r.meta.icon,
                    routeName: r.name
                }
            });
            return [...routeItems, ...this.defaultNavigationItems];
        },
        ...mapGetters('auth', ['user']),
    },
    methods: {
        getAvatar(input) {
            if (!input) return '';
            return input.split(" ").reduce((c, part) => {
                if (c.length < 2) {
                    c += part.charAt(0).toUpperCase()
                }
                return c;
            }, "");
        },
        navigationItemClick(e) {
            let {itemData} = e;
            if (itemData.action) {
                itemData.action();
            } else {
                // Treat items without action as normal route links
                if (this.$router.currentRoute.name !== itemData.routeName) {
                    this.$router.push({name: e.itemData.routeName});
                    this.drawerOpened = false;
                }
            }
        },
        triggerLogout() {
            this.$store.dispatch('auth/logout');
        }
    }
}
</script>


<style lang="scss">
.avatar {
    $avatar-size: 2rem;
    width: $avatar-size;
    height: $avatar-size;
    border-radius: $avatar-size;
    background: #dfdfdf;
    color: white;
}

.drawer-container {
    .dx-overlay-wrapper {
        height: 100% !important;
    }

    .dx-drawer-panel-content {
        .dx-template-wrapper,
        .panel-list {
            height: 100%;
        }
    }
}

.drawer-container {
    height: calc(100vh - 50px);

    .dx-list-item-icon-container,
    .dx-toolbar-before {
        width: 36px;
        padding-right: 0 !important;
        text-align: center;
    }

    .dx-list-item-content {
        padding-left: 10px !important;
    }

    .drawer-toolbar {
        .dx-button {
            background-color: rgba(191, 191, 191, -0.15);
            border: none;
        }

        &.dx-toolbar {
            background-color: rgba(191, 191, 191, 0.15);
            padding: 5px 10px;
        }
    }

    .panel-list .dx-list-item {
        color: #fff;
        border-top: 1px solid rgba(221, 221, 221, 0.2);
    }

    .panel-list .dx-list-item .dx-icon {
        color: #fff !important;
    }

    .caption {
        font-size: 18px;
        font-weight: 500;
    }

    .option {
        margin-top: 10px;
        display: inline-block;
        margin-right: 50px;
    }

    label {
        font-weight: bold;
    }

    #content {
        height: 100%;
        padding: 10px 20px;
    }

    #content h2 {
        font-size: 26px;
    }
}
</style>
